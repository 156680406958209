
  import { useStore } from "vuex";
  import { computed, onUpdated } from "vue";
  
  export default {
    name: "ForwarderJobsTabs",
    emits: ["changeTab"],
  
    setup(props, { emit }) {
      const store = useStore();
      const forwarderJobId = computed(() => store.getters.ForwarderOfferId);
      const forwarderJob = computed(() => store.getters.ForwarderOfferItem);
      const forwarderJobTab = computed(() => store.getters.ForwarderTabItem);
  
      const handleTabClick = (tab) => {
        emit("changeTab", tab);
      };
  
      onUpdated(() => {
        if ("tab" in forwarderJobTab.value) {
          const overview = document.getElementById("overview") as any;
          const tab = document.getElementById(forwarderJobTab.value.tab) as any;
          tab.classList.add("active");
          overview.classList.remove("active");
          emit("changeTab", forwarderJobTab.value.tab);
          store.commit("removeForwarderTab");
        }
      });
  
      return {
        props,
        forwarderJobId,
        forwarderJob,
        handleTabClick,
      };
    },
  };
  