
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import ForwarderAirFreightCompanySelect from "@/components/catalogs-select/ForwarderAirFreightCompanySelect.vue";
import { useStore } from "vuex";

interface FormCatalog {
  id?: number;
  forwarder_air_freight_company_id?: number;
  number: string;
  national: number;
  sequence_length: number;
}

export default defineComponent({
  name: "ForwarderAirWayBillForm",
  components: {
    Form,
    Field,
    ErrorMessage,
    ForwarderAirFreightCompanySelect,
  },
  props: {
    entityId: String,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const form = ref<FormCatalog>({
      forwarder_air_freight_company_id: undefined,
      number: "",
      national: 0,
      sequence_length: 1,
    });

    const isNumberRequired = (value) => {
      if (!value) {
        return t("rnumber");
      }
      return true;
    };

    onMounted(() => {
      if (props.entityId) {
        store.commit("setLoadingStatus", true);
        ApiService.get(
          "/api/forwarder/voyage/air_way_bill/" + props.entityId
        ).then(({ data }) => {
          form.value = { ...data };
          store.commit("setLoadingStatus", false);
        });
      }
    });

    const createElement = async (resetForm) => {
      {
        if (
          form.value.number.length !== 11 ||
          parseInt(form.value.number.charAt(9)) > 6
        ) {
          try {
            store.commit("setLoadingStatus", true);
            if (form.value.sequence_length < 15) {
              const resp = (
                await ApiService.post(
                  "/api/forwarder/voyage/air_way_bill",
                  form.value
                )
              ).data;
              emit("success", resp);
              resetForm();
              submitButton.value?.removeAttribute("data-kt-indicator");
            } else {
              ElNotification({
                title: "Error",
                message: "length minimum 15",
                type: "error",
              });
              return;
            }
          } catch (error) {
            store.commit("setLoadingStatus", false);
            submitButton.value?.removeAttribute("data-kt-indicator");
          }
        } else {
          ElNotification({
            title: "Error",
            message:
              "Number does not have 10 characters and the last number cannot be greater than 6",
            type: "error",
          });
          return;
        }
      }
    };

    const updateElement = (resetForm) => {
      store.commit("setLoadingStatus", true);
      ApiService.put(
        "/api/forwarder/voyage/air_way_bill/" + props.entityId,
        form.value
      )
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
          resetForm();
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const onSumbit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateElement(resetForm);
        } else {
          createElement(resetForm);
        }
      }
    };

    return {
      submitButton,
      form,
      onSumbit,
      isNumberRequired,
      updateElement,
      createElement,
    };
  },
});
