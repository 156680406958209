
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "ForwarderAirFreightCompanySelect",
  props: {
    forwarder_air_freight_company_id: {
      type: [Number, Array],
      default: undefined,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:forwarder_air_freight_company_id"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const inputAirFreightCompany = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isAirFreightCompanyRequired = (value) => {
      if (props.required && !value) {
        return t("airfreightcompanyid");
      }
      return true;
    };

    const {
      errorMessage,
      value: element_id,
      meta,
    } = useField(
      "forwarder_air_freight_company_id",
      isAirFreightCompanyRequired
    );

    const getAirFreightCompany = () => {
      ApiService.get("/api/forwarder/voyage/air_freight_company").then(
        ({ data }) => {
          inputAirFreightCompany.value.list =
            data.forwarder_air_freight_companies;
          inputAirFreightCompany.value.options =
            data.forwarder_air_freight_companies;
        }
      );
    };
    const selectAirFreightCompany = (query) => {
      if (query !== "") {
        inputAirFreightCompany.value.loading = true;
        setTimeout(() => {
          inputAirFreightCompany.value.loading = false;
          inputAirFreightCompany.value.options =
            inputAirFreightCompany.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
          if (!inputAirFreightCompany.value.options.length) {
            ApiService.query(`/api/forwarder/voyage/air_freight_company`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              inputAirFreightCompany.value.list =
                data.forwarder_air_freight_companies.name;
              inputAirFreightCompany.value.options =
                data.forwarder_air_freight_companies.name;
            });
          }
        }, 200);
      } else {
        inputAirFreightCompany.value.options = [];
      }
    };

    watch(
      () => props.forwarder_air_freight_company_id,
      (first) => {
        element_id.value = first;
      }
    );

    setTimeout(() => {
      getAirFreightCompany();
    });

    return {
      element_id,
      inputAirFreightCompany,
      errorMessage,
      meta,
      getAirFreightCompany,
      selectAirFreightCompany,
    };
  },
};
